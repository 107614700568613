import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { fetchAuth, selectIsAuth } from '../../store/slices/auth'
import styles from "../RegistrationPage/Registration.module.css";
import personImg from "../../assets/images/mainImg.png";

import './LoginPage.scss'
import LoginForm from '../../components/LoginForm'
import LoginSms from './LoginSms'

const LoginPage = () => {
  const isAuth = useSelector(selectIsAuth)
  const error = useSelector(state => state.auth.error)

  const dispatch = useDispatch()

  const { register, handleSubmit, setError, formState: { errors, isValid } } = useForm({
    defaultValues: {
      email: "",
      password: ""
    }, mode: 'onChange'
  })




  if (isAuth) {
    return <Navigate to='/' />
  }
  return (
    <div className={styles.register}>
      <div className="container">
        <section className={styles.main}>
          <div className={styles.registerLeft}>
              {/* <LoginForm styles={styles} /> */}
              <LoginSms styles={styles} />
          </div>
          <div className={styles.registerRight}>
            <img src={personImg} alt="" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default LoginPage