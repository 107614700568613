import React from "react";

const VideoIframe = ({ lessonUrl }) => {
  console.log("lessonUrl", lessonUrl);
  return lessonUrl ? (
    <div className="videoPlayer">
      <div className="videoPlayerblock"></div>
      {/* <div className="videoPlayerblockBottom"></div> */}
      <iframe
        width="100%"
        height="100%"
        src={`${lessonUrl}`}
        allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media"
        frameBorder="0"
        scrolling="no"
        allowfullscreen
      ></iframe>
      {/* <iframe
        title={"Alippe"}
        frameBorder={0}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
      ></iframe> */}
    </div>
  ) : (
    <div className="videoPlayer ">Сабакты тандаңыз...</div>
  );
};

export default VideoIframe;
