import React from "react";
import styles from "./MainPage.module.css";
import aboutImg from "../../assets/images/aboutImg.png";
import licenseImg from "../../assets/images/license.png";
import kubolukImg from "../../assets/images/kuboluk.png";
import personImg from "../../assets/images/mainImg.png";
import Testimonials from "../../components/Testimonials";

const HomePage = () => {
  return (
    <div className={styles.main}>
      <div className="container">
        <section className={styles.hero}>
          <div className={styles.heroLeft}>
            <h1 className={styles.heroTitle}>Alippe Pro</h1>
            <p className={styles.heroDescr}>
              Учурдаң талабына жооп берген билим берүү аянтчасы
            </p>
          </div>
          <div className={styles.heroRight}>
            <img src={personImg} alt="" />
          </div>
        </section>
        <p className={styles.heroLabel}>200+ мугалим биздин катарыбызда</p>
        <section id="about" className={styles.about} >
          <p className={styles.aboutUs}>
            ——— &nbsp; &nbsp; Эмне үчүн Alippe Pro?
          </p>
          <h2 className={styles.aboutTitle}>Биз кимбиз?</h2>
          <div className={styles.aboutContent}>
            <div className={styles.aboutImage}>
              <img src={aboutImg} alt="" />
            </div>
            <div className={styles.aboutText}>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;<b>"Pro"</b> сөзү кыргызча "жаңыланган"
                деген маанини берет. <br /> <br /> Жаңыланган алиппе
                мугалимдерге: <br /> <br />{" "}
              </p>
              <ul className={styles.aboutList}>
                <li>
                  <a>Жаңыланган методикалар</a>
                </li>
                <li>
                  <a>заманбап билимдер</a>
                </li>
                <li>
                  <a>Университетте үйрөтүлгөн көндүмдөр</a>
                </li>
                <li>
                  <a>Лайфхактардын түрлөрү</a>
                </li>
                <li>
                  <a>Мотивация үчүн көнүгүүлөр</a>
                </li>
              </ul>
            </div>
          </div>
          <p className={styles.aboutFooter}>
            Жаңы муунга жараша жаңыланган методика, жаңыланган сабактар болушу
            керек!
          </p>
        </section>
      </div>
      <section className={styles.certificates}>
        <div className="container">
          <h2 className={styles.certificatesTitle}>Alippe Pro</h2>
          <p className={styles.certificatesDescr}>
            Мектепте <b>“Ал эжейдин сабагы скучный”</b> деген тизмеде болгусу
            келбеген, изденип турган мугалимдердин жоон тобу менен иштешебиз
          </p>
          <div className={styles.certificateImages}>
            <div className={styles.certificateImgLeft}>
              <img
                src={licenseImg}
                alt="Certificate 1"
                className={styles.certificateImage1}
              />
              <p className={styles.certificateImageDescr}>
                Ишмердүүлүк КР билим берүү жана илим министрлиги тарабынан
                лицензияланган
              </p>
            </div>
            <div className={styles.certificateImgRight}>
              <img
                src={kubolukImg}
                alt="Certificate 2"
                className={styles.certificateImage2}
              />
              <p className={styles.certificateImageDescr}>
                КР Кыргызпатент иш канасы тарабынан "шар окуу" методикасына
                автордук укук берилген.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Testimonials styles={styles} />
    </div>
  );
};

export default HomePage;
