import React from "react";
import styles from "./Footer.module.css";
import Whatsapp from "../../assets/images/wh.png";
import TikTok from "../../assets/images/tiktok.png";
import Insta from "../../assets/images/insta.png";
import Location from "../../assets/images/location.png";
import Download from "../../assets/images/download.png";
import { FaGooglePlay } from "react-icons/fa";
import { FaAppStoreIos } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className={styles.footerContainer}>
          <div className={styles.footerContent}>
            <div className={styles.footerText}>
              <h1>
                &nbsp;&nbsp;&nbsp;&nbsp;Биз менен жакындан таанышкыңыз келеби?
              </h1>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;Түпкү маанибиз - жаңы муунга жараша
                жаңыланган методика, жаңыланган сабактар болушу керек!
              </p>
            </div>
            <div className={styles.footerButtons}>
              <a href="https://api.whatsapp.com/send/?phone=996509190605" className={styles.footerBtn}>
                <img src={Whatsapp} alt="" />
                <p>Билдирүү калтыруу</p>
              </a>
              <a href="https://www.instagram.com/alippepro/"  className={styles.footerBtn}>
                <img src={Insta} alt="" />
                <p>Бизге катталуу</p>
              </a>
              <a href="https://www.tiktok.com/@alippe_pro" className={styles.footerBtn}>
                <img src={TikTok} alt="" />
                <p>Пайдалуу видеолор</p>
              </a>
              <div className={styles.footerBottom}>
                <img src={Location} alt="" />
                <p>Бишкек ш&nbsp;&nbsp;&nbsp;&nbsp;Исанова 102</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footerBottomBtn}>
          <a href="https://play.google.com/store/apps/details?id=com.alippe.alippepro_v1&hl=ru&pli=1" className={styles.footerDowmload}>
            <p>Тиркемени көчүрүү</p>
            <FaGooglePlay size={24}/>
          </a>
          <a href="https://apps.apple.com/kz/app/alippepro-%D0%B0%D0%BB%D0%B8%D0%BF%D0%BF%D0%B5%D0%BF%D1%80%D0%BE/id6483004173" className={styles.footerDowmload}>
            <p>Тиркемени көчүрүү</p>
           <FaAppStoreIos size={24}/>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
