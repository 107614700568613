import React, { useEffect, useRef, useState } from "react";
import Video from "./Video";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  fetchLesson,
  fetchLessons,
  removeLesson,
} from "../../store/slices/course";
import { FiLock } from "react-icons/fi";
import ReactPlayer from "react-player";
import VideoRumble from "./VideoOdysee";
import VideoIframe from "./VideoIframe";

const ModulesList = () => {
  const lessons = useSelector((state) => state.courses?.lessons);
  const lesson = useSelector((state) => state.courses?.lesson);
  const userModules = useSelector((state) => state.auth.data?.courses);
  const userId = useSelector((state) => state.auth?.data?.id);
  const [active, setActive] = useState(null);
  let modulesIds = [];
  let lessonIds = [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchLessons({ id, userId }));
    dispatch(removeLesson());
    window.scrollTo(0, 0);
  }, [dispatch, id, userId]);

  

  modulesIds = userModules?.map((item) => item);
  lessonIds = lessons && lessons.modules.map((item) => item);
  let arrayIds = modulesIds?.concat(lessonIds);

  let modules = [];
  for (let i = 0; i < arrayIds?.length; i++) {
    for (let k = 0; k < arrayIds?.length; k++) {
      if (k !== i) {
        if (arrayIds[i]?._id === arrayIds[k]?._id) arrayIds[k] = "";
      }
    }
  }
  for (let i = 0; i < arrayIds?.length; i++) {
    if (arrayIds[i] === "") continue;
    else modules.push(arrayIds[i]);
  }

  const getLesson = (params) => {
    handleClick();
    setActive(params.lessonId);
    dispatch(fetchLesson(params));
  };

  const targetRef = useRef(null);

  const handleClick = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return userId ? (
    <div className="modules__list">
      <li className="warning" ref={targetRef}>
        <b>!</b>
        <p>
          ЭСКЕРТҮҮ: бул берилгендер сизге методикалык пособие болуп берет жана
          сизде чыгармачылыкты, креативдүүлүктү, методика иштеп чыгууну жаратат
        </p>
      </li>
      <div className="modules__video">
        {/* <VideoRumble lessonUrl={lesson?.rumbleUrl} /> */}
        <VideoIframe lessonUrl={lesson?.rumbleUrl} />
      </div>
      <p className="lineText modules__linetext">
        ——— &nbsp; &nbsp; Сабакты тандаңыз
      </p>
      <div className="modules__list">
        {modules?.map((module, i) => (
          <div key={i}>
            {module?.isAccess === false ? (
              module?.courseId === id && (
                <div className="modules__item" key={i}>
                  <div className="modules__item-header">
                    <h3 className="modules__item-title elippsis">
                      {module?.name}
                    </h3>
                    <div className="modules__item-btn">модулга доступ ачык</div>
                  </div>
                  <ul className="modules__lessons">
                    {module?.lessons.map((lesson, i) => (
                      <li
                        key={lesson._id}
                        onClick={() =>
                          getLesson({
                            courseId: lessons._id,
                            lessonId: lesson._id,
                          })
                        }
                        className="modules__lesson"
                      >
                        <div className="modules__lesson-number">
                          {i + 1}-cабак
                        </div>{" "}
                        <div className="modules__lesson-title">
                          {lesson.name}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            ) : (
              <div className="modules__item">
                <div className="modules__disabled"></div>
                <div className="modules__item-header">
                  <h3 className="modules__item-title elippsis">
                    {module?.name}
                  </h3>
                  <a
                    href="https://api.whatsapp.com/send/?phone=996509190605"
                    className="modules__item-btnBuy"
                  >
                    модулга доступ алуу
                  </a>
                </div>
                <ul className="modules__lessons">
                  {module?.lessons.map((lesson, i) => (
                    <li
                      key={lesson._id}
                      onClick={() =>
                        getLesson({
                          courseId: lessons._id,
                          lessonId: lesson._id,
                        })
                      }
                      className="modules__lesson"
                    >
                      <div className="modules__lesson-number">
                        {i + 1}-cабак
                      </div>{" "}
                      <div className="modules__lesson-title">{lesson.name}</div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className="">Курстарды коруу учун, сайтка катталыныз!</div>
  );
};

export default ModulesList;
