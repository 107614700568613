import React, { useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

import "./Courses.scss";
import { Link } from "react-router-dom";
import { fetchAllCourses } from "../../store/slices/course";

const Courses = () => {
  const courses = useSelector((state) => state.courses.courses);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllCourses());
  }, []);

  return (
    <div className="courses">
      <div className="courses__container">
        <div className="courses__header">
          <div className="courses__search">
            <div className="courses__searchLeft">
              <FiSearch size={15} color="#006A6E" />
              <input type="text" placeholder="Сизге керектүү курсту издеңиз..." />
            </div>
            <button>Издөө</button>
          </div>
        </div>
        <ul className="courses__items">
          {courses &&
            courses.map((course) => (
              <Link to={"/course/" + course.id} key={course.id}>
                <div className="courses__item">
                  <div className="courses__content">
                    <div className="courses__name">{course.title}</div>
                    <div className="courses__descr">
                      Жаңы муунга ылайыкталган методика
                    </div>
                    <div className="courses__duration">{course.duration}</div>
                    <button>Кененирээк</button>
                  </div>
                  <div className="courses__img">
                    <img src={course.previewImgUrl} alt="" />
                  </div>
                </div>
              </Link>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Courses;
