import React, { useEffect } from "react";
import Activity from "../../assets/images/Activity.png";
import Wallet from "../../assets/images/Wallet.png";
import Document from "../../assets/images/Document.png";

const CourseDetails = ({ course }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="course__details">
      <p className="lineText">——— &nbsp; &nbsp; Сиз</p>
      <div className="course__details-container">
        <h2 className="course__details-title">
          <b>{course?.title}</b>
          <br /> курсун окуу менен кандай <br /> билимге ээ болосуз?
        </h2>
        <ol className="course__details-list">
          <li>
            <b>1.</b>
            <p>Азыркы учурдагы Z жана α муунунун табиятын тааныганга жол ачылат. Аларга 5 тараптуу диагностика кылууну үйрөнөсүз.</p>
          </li>
          <li>
            <b>2.</b>
            <p>Окуучуларыңызды мектептеги баалоодон кетип жаткан мүчүлүштүктөргө тоскоол болгон рамкадан чыгып, аларды деңгээлге бөлүп иштөөнү үйрөнөсүз;</p>
          </li>
          <li>
            <b>3.</b>
            <p>Эң маанилүүсү, учурдагы сизди кыйнаган окуучулардагы көйгөйлөрдү жоюуга арналган 20 методика +70тен ашык оюн көнүгүүнү үйрөнөсүз.</p>
          </li>
          <li className="warning">
            <b>!</b>
            <p>ЭСКЕРТҮҮ: бул берилгендер сизге методикалык пособие болуп берет жана сизде чыгармачыдыкты, креативдүүлүктү, методика иштеп чыгууну жаратат</p>
          </li>
          <li>
            <b>4.</b>
            <p>Сизге биз тараптан, ишиңизди улантып, өсүп-өнүгүү үчүн ШАР ОКУУ китебинин электрондук варианты берилет. Шар окуу курсун аяктаган соң окуучуну сабактан кийин алып калуунун кереги жок!</p>
          </li>
        </ol>
      </div>
      <p className="lineText">——— &nbsp; &nbsp; Сиз</p>
      <div className="course__benefits-container">
        <h2 className="course__details-title">
          <b>Жыйынтыгында эмнеге ээ болосуз?</b>
        </h2>
        <div className="course-benefits">
          <div className="course__benefits-item">
            <div className="course__benefits-img">
              <img src={Activity} alt="Activity" />
            </div>
            <div className="course__benefits-title">Жеке өнүгүү</div>
            <p className="course__benefits-descr">Заманбап жана эффективдүү методикалар менен билим куржунуңузду толтурасыз.</p>
          </div>
          <div className="course__benefits-item">
            <div className="course__benefits-img">
              <img src={Wallet} alt="Wallet" />
            </div>
            <div className="course__benefits-title">Киреше булагы же өз алдынча бизнесиңиз</div>
            <p className="course__benefits-descr">Элде жок методика менен окуучуларды билимге сугарып жатып жеке ишкердик менен алектенүүгө мүмкүнчүлүк аласыз.</p>
          </div>
          <div className="course__benefits-item">
            <div className="course__benefits-img">
              <img src={Document} alt="Document" />
            </div>
            <div className="course__benefits-title">Сертификат</div>
            <p className="course__benefits-descr">Курстун акырында "шар окуу" методикасын үйрөнгөндүгүңүздү тастыктаган сертификатка ээ болосуз.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetails;
