import React from "react";

const Testimonials = ({ styles }) => {
  return (
    <section className={styles.testimonials}>
      <div className="container">
        <p className={styles.testimonialsText}>
          ——— &nbsp; &nbsp; Биздин жумуш боюнча
        </p>
        <h2 className={styles.testimonialsTitle}>ой-пикирлер</h2>
        <div className={styles.testimonialCards}>
          <div className={styles.testimonialCard}>
            <div className={styles.testimonialCardContainer}>
              <div className={styles.testimonialCardHeader}>
                <div className={styles.testimonialCardImg}><img src="https://static.vecteezy.com/system/resources/previews/001/993/889/non_2x/beautiful-latin-woman-avatar-character-icon-free-vector.jpg" alt="" /></div>
                <div className={styles.testimonialCardName}>Назгуль</div>
              </div>
              <div className={styles.testimonialCardDescr}>
                &nbsp; &nbsp; Мен дагы ойдо жок жерден эле бул долбоорго катышып
                калдым.Башка кесиптештер сыяктуу мен да жакшы,пайдалуу билим
                алдым.Өкүнбөйм бул долбоорго катышып калганыма.
              </div>
            </div>
          </div>
          <div className={styles.testimonialCard}>
            <div className={styles.testimonialCardContainer}>
              <div className={styles.testimonialCardHeader}>
                <div className={styles.testimonialCardImg}><img src="https://static.vecteezy.com/system/resources/thumbnails/004/899/680/small_2x/beautiful-blonde-woman-with-makeup-avatar-for-a-beauty-salon-illustration-in-the-cartoon-style-vector.jpg" alt="" /></div>
                <div className={styles.testimonialCardName}>Гүлнара</div>
              </div>
              <div className={styles.testimonialCardDescr}>
                &nbsp; &nbsp; Бул долбоорго катышуу мага күтүлбөгөн
                жакшылыктарды алып келди. Мен жогорку сапатта билим алдым жана
                бул тажрыйба мага чоң пайдасын тийгизди.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
