import React, { useEffect, useState } from "react";
import "./DetailPage.scss";
import CourseDetails from "./CourseDetail";
import ModulesList from "./ModuleList";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchCourse } from "../../store/slices/course";

const DetailPage = () => {
  const [selected, setSelected] = useState(true);
  const course = useSelector((state) => state.courses.courseDetail);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const loadCourse = async () => {
      try {
        await dispatch(fetchCourse(id));
      } catch (error) {
        console.error("Error fetching course:", error);
      }
    };

    loadCourse();
  }, [dispatch, id]);

  useEffect(() => {
    console.log('Course:', course);
  }, [course]);

  return (
    <div className="container">
      <div className="course-overview">
        <div className="course-left">
          <h1 className="course-leftTitle">{course?.title || "Загрузка..."}</h1>
          <p className="course-leftDescr">
            Жаңы муунга ыӊгайлаштырылган <br /> методика
          </p>
          <div className="course-info">
            <span>{course?.duration || "Загрузка..."}</span>
          </div>

          <div className="course-price">
            <div className="course-buttons">
              <a href="https://api.whatsapp.com/send/?phone=996509190605">Курска катышуу</a>
            </div>
            <div className="course-priceInfo">
              <div>{course?.price ? `${course.price}c` : "Загрузка..."}</div>
              <div className="course-priceDisabled">
                {course?.price ? `${course.price + 20000} c` : "Загрузка..."}
              </div>
            </div>
          </div>
        </div>
        <div className="course-right">
          {course?.previewImgUrl ? <img src={course.previewImgUrl} alt="" /> : "Загрузка..."}
        </div>
      </div>

      <div className="course__choice">
        <button
          className={selected ? "selected" : ""}
          onClick={() => setSelected(true)}
        >
          Курс тууралуу
        </button>
        <button
          className={!selected ? "selected" : ""}
          onClick={() => setSelected(false)}
        >
          Сабактарды көрүү
        </button>
      </div>
      {selected ? <CourseDetails course={course} /> : <ModulesList />}
    </div>
  );
};

export default DetailPage;
