import React from "react";
import Courses from "../../components/Courses";

import "./CoursesPage.scss";

const CoursesPage = () => {
  return (
    <div className="courses">
      <div className="courses__container container">
        <h2  className="courses__title"> Авторитет мугалим болууга кадам! Сизге керектүү билимди алыңыз</h2>
        <Courses />
      </div>
    </div>
  );
};

export default CoursesPage;
