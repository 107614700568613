import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { fetchAuth } from "../../store/slices/auth";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

var verificationCodeData = 0;
var name = "";
var responseDataGlobal;

const random = Math.floor(100000 + Math.random() * 900000);
verificationCodeData = random;

const LoginSms = ({ styles }) => {
  const dispatch = useDispatch();
  const [verify, setVerify] = useState(false);
  // const [name, setName] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      code: "",
      //   password: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .required("обязательное поле")
        .min(9, "Must be exactly 9 digits")
        .max(10, "Must be exactly 10 digits"),
      code: Yup.string(),
    }),
    onSubmit: async (values) => {
      if (!verify) {
        startVerification(values.phone);
        setVerify(true);
        name = values.name;
      } else {
        function removeLeadingZero(str) {
          if (str.startsWith("0")) {
            values.phone = values.phone.slice(1);
          }
          return "996" + values.phone;
        }
        const phoneNumber = removeLeadingZero(values.phone);
        verifyCode(phoneNumber, values.code);
        name = values.name;
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className={styles.registerTitle}>Кирүү</h2>
      <div className={styles.registerBlock}>
        <input
          type="string"
          placeholder="Атынызды жазыныз"
          className={styles.registerInput}
          {...formik.getFieldProps("name")}
        />
      </div>
      <div className={styles.registerBlock}>
        {/* <label>Телефон номериңиз</label> */}
        <input
          type="string"
          placeholder="Телефон номериңиз (0555123344)"
          className={styles.registerInput}
          onFocus={() => setVerify(false)}
          {...formik.getFieldProps("phone")}
        />

        {formik.touched.phone && formik.errors.phone ? (
          <div className={styles.registerError}>{formik.errors.phone}</div>
        ) : null}
      </div>
      {verify ? (
        <div className={styles.registerBlock}>
          <input
            type="string"
            placeholder="CMC кодду териниз"
            className={styles.registerInput}
            {...formik.getFieldProps("code")}
          />
        </div>
      ) : null}

      <button type="submit" className={styles.registerSubmit}>
        Кирүү
      </button>
      <p className={styles.registerSign}>
        Эгер аккаунт жок болсо
        <Link to="/register">, катталуу</Link>
      </p>
    </form>
  );
};

export default LoginSms;

const startVerification = async (phoneNumber) => {
  try {
    function removeLeadingZero(str) {
      if (str.startsWith("0")) {
        phoneNumber = phoneNumber.slice(1);
      }
      return "996" + phoneNumber;
    }
    phoneNumber = removeLeadingZero(phoneNumber);
    console.log(phoneNumber);
    const verificationCode = "999999";
    const context = "999999";

    // if (phoneNumber.toString() === "996990859695") {
    //   console.log('!!!!!!',phoneNumber.toString());
    //   return verifyCode(context, phoneNumber, verificationCodeData);
    // }

    const login = "janbolot12";
    const password = "azWEKYpG";
    const sender = "AlippeProKG";
    const transactionId = Math.floor(10000000 + 100000000 * Date.now());
    const text = `Саламатсызбы! ${random}\nБул кодду AlippePro тирекемесине киргизиниз.`;
    const xmlData = `<?xml version="1.0" encoding="UTF-8"?>
    <message>
        <login>${login}</login>
        <pwd>${password}</pwd>
        <id>${transactionId}</id>
        <sender>${sender}</sender>
        <text>${text}</text>
        <phones>
            <phone>${phoneNumber}</phone>
        </phones>
    </message>`;
    const response = await fetch("https://smspro.nikita.kg/api/message", {
      method: "POST",
      headers: {
        "Content-Type": "application/xml",
      },
      body: xmlData,
    });

    if (response.ok) {
      const jsonResponse = await response.text();
      // history.push('/otp-verification', { phoneNumber });
    } else {
      // setError('Failed to send SMS. Status code: ' + response.status);
      console.log("error");
    }
  } catch (error) {
    console.log(error);
    //   setError('Error: ' + error.message);
  }
};

const verifyCode = async (phoneNumber, verificationCode) => {
  const apiUrl = `https://alippe-backend-v1-9yvg.onrender.com/api/auth/verify-code`;

  const requestBody = { phoneNumber };

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const responseData = await response.json();
    responseDataGlobal = responseData;

    if (verificationCodeData.toString() === verificationCode.toString()) {
      const userData = {
        name: name,
      };
      setUserData(userData);
      return { success: true, data: responseData };
    } else {
      return { success: false, message: "Incorrect verification code" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

async function setUserData(userData) {
  try {
    // Получение данных пользователя из localStorage
    const userDataLocalStorage = localStorage.getItem("user");
    const userDataDecoded = JSON.parse(userDataLocalStorage);
    const userId = responseDataGlobal.data.id;

    const apiUrl = `https://alippe-backend-v1-9yvg.onrender.com/api/auth/update-data?userId=${userId}`;

    const requestBody = {
      name: userData.name,
      // id: userData.name,
    };

    // Отправка PATCH-запроса с использованием axios
    const response = await axios.patch(apiUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const responseData = response.data;

    if (response.status === 200) {
      localStorage.setItem("user", JSON.stringify(responseData.data.userData));
      localStorage.setItem("token", JSON.stringify(responseData.data.token));
      // Переход на главную страницу
      window.location.href = "/";
      return response;
    } else {
      return responseData;
    }
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
}
