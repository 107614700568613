import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchAuth } from "../../store/slices/auth";
// import styles from "../../pages/MainPage/MainPage.module.css";

const LoginForm = ({ styles }) => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      password: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .required("обязательное поле")
        .matches(/^[0-9]+$/, "Введите только цифры")
        .min(9, "Must be exactly 9 digits")
        .max(10, "Must be exactly 10 digits"),
      password: Yup.string()
        .required("обязательное поле")
        .min(6, "6 символдон жогоруу болушу зарыл"),
    }),
    onSubmit: async(values) => {
        const data = await dispatch(fetchAuth(values))
        if (!data.payload) {
          return
        }
        if ('token' in data.payload) {
          window.localStorage.setItem('token', data.payload.token)
        }
      }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className={styles.registerTitle}>Кирүү</h2>

      <div className={styles.registerBlock}>
        {/* <label>Телефон номериңиз</label> */}
        <input
          type="number"
          placeholder="Телефон номериңиз"
          className={styles.registerInput}
          {...formik.getFieldProps("phone")}
        />
        {formik.touched.phone && formik.errors.phone ? (
          <div className={styles.registerError}>{formik.errors.phone}</div>
        ) : null}
      </div>
      <div className={styles.registerBlock}>
        {/* <label>Сыр сөз ойлоп табыңыз</label> */}
        <input
          placeholder="Сыр сөздү жазыңыз"
          className={styles.registerInput}
          type="password"
          {...formik.getFieldProps("password")}
        />
        {formik.touched.password && formik.errors.password ? (
          <div className={styles.registerError}>{formik.errors.password}</div>
        ) : null}
      </div>
      <button type="submit" className={styles.registerSubmit}>
        Кирүү
      </button>
      <p className={styles.registerSign}>
        Эгер аккаунт жок болсо
        <Link to="/register">, катталуу</Link>
      </p>
    </form>
  );
};

export default LoginForm;
