import React, { useEffect } from "react";
import { useState } from "react";
import VideoPlayer from "react-video-player-extended";

const VideoRumble = ({ lessonUrl }) => {
  // useEffect(() => {
  //   // Создаем и добавляем первый скрипт
  //   const script1 = document.createElement("script");
  //   script1.innerHTML = `!function(r,u,m,b,l,e){r._Rumble=b,r[b]||(r[b]=function(){(r[b]._=r[b]._||[]).push(arguments);if(r[b]._.length==1){l=u.createElement(m),e=u.getElementsByTagName(m)[0],l.async=1,l.src="https://rumble.com/embedJS/u3ru6zz"+(arguments[1].video?'.'+arguments[1].video:'')+"/?url="+encodeURIComponent(location.href)+"&args="+encodeURIComponent(JSON.stringify([].slice.apply(arguments))),e.parentNode.insertBefore(l,e)}})}(window, document, "script", "Rumble");`;

  //   document.body.appendChild(script1);

  //   // Создаем и добавляем второй скрипт
  //   const script2 = document.createElement("script");
  //   script2.innerHTML = ` Rumble("play", {video: "v544r2t", div: "rumble_v544r2t", api: function(api) {

  //     console.log(API.getDuration());
  //   }});`;

  //   document.body.appendChild(script2);

  //   // Очистка скриптов после размонтирования компонента
  //   return () => {
  //     document.body.removeChild(script1);
  //     document.body.removeChild(script2);
  //   };
  // }, []);
  const url = `${lessonUrl}&rel=5&autoplay=2`;
  console.log(url);
  return (
    <>
      {lessonUrl ? (
        // <div id="rumble_v544r2t"></div>
        <div className="videoPlayer">
          <div className="rumbleBlock"></div>
          <div className="rumbleBlockHeader"></div>
          <iframe
            className="rumble"
            width="100%"
            height="100%"
            src={url}
            frameBorder={"0"}
            allowFullScreen={"allowfullscreen"}
          ></iframe>
        </div>
      ) : (
        <div className="videoPlayer ">Сабакты тандаңыз...</div>
      )}
    </>
  );
};

export default VideoRumble;
